var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"from_outer"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.info.department)+" - "+_vm._s(_vm.info.name)+"(风险告知卡)")]),_c('div',{staticClass:"card-strip"},[_vm._m(0),_c('div',{staticClass:"card-4"},[_c('p',{staticClass:"p1"},[_vm._v(" "+_vm._s(_vm.info.check_times)+" ")])]),_vm._m(1),_c('div',{staticClass:"card-4",staticStyle:{"padding":"0"}},_vm._l((_vm.color_list),function(item,index){return _c('div',{key:index,staticClass:"grade",style:({
            color: item.active ? '#ffffff' : '',
            background: item.active ? item.color : '',
          })},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"card-strip"},[_vm._m(2),_c('div',{staticClass:"card-4"},[_c('p',{staticClass:"p1"},[_vm._v(" "+_vm._s(_vm.info.department)+" ")])]),_vm._m(3),(_vm.info.liableMan)?_c('div',{staticClass:"card-4"},[(_vm.info.liableMan.length != 0)?_c('p',{staticClass:"p1"},[_vm._v(" "+_vm._s(_vm.info.liableMan[0].nickname)+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"card-strip"},[_vm._m(4),_c('div',{staticClass:"card-4"},[_c('p',{staticClass:"p1"},[_vm._v(" "+_vm._s(_vm.safety_person)+" ")])]),_vm._m(5),_c('div',{staticClass:"card-4"},[_c('div',{staticClass:"safety"},_vm._l((_vm.safetyMan),function(item,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(item.nickname)+" - "+_vm._s(item.mobile)+" ")])}),0)])]),_c('div',{staticClass:"card-strip"},[_vm._m(6),_c('div',{staticClass:"card-2"},[_c('p',{staticClass:"p1"},[_vm._v(" "+_vm._s(_vm.info.ext_causes)+" ")])])]),_c('div',{staticClass:"card-strip"},[_vm._m(7),_c('div',{staticClass:"card-2"},[_c('p',{staticClass:"p1"},[_vm._v(" "+_vm._s(_vm.info.ext_danger)+" ")])])]),_c('div',{staticClass:"card-strip"},[_vm._m(8),_c('div',{staticClass:"card-2"},[_c('p',{staticClass:"p1"},[_vm._v(_vm._s(_vm.info.ext_controll))])])]),_c('div',{staticClass:"card-strip"},[_vm._m(9),_c('div',{staticClass:"card-2"},[_c('p',{staticClass:"p1"},[_vm._v(_vm._s(_vm.info.ext_plans))])])]),_c('div',{staticClass:"card-strip"},[_vm._m(10),_c('div',{staticClass:"card-2",staticStyle:{"justify-content":"center"}},_vm._l((_vm.img_List),function(item,index){return _c('el-image',{key:index,staticClass:"img-sty",attrs:{"src":item}})}),1)]),_c('div',{staticClass:"card-strip"},[_vm._m(11),_c('div',{staticClass:"card-2",staticStyle:{"justify-content":"center"}},[_c('el-image',{staticClass:"img-ewm",attrs:{"src":_vm.info.qr_code}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("检查频率")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("风险等级")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("责任部门")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("责任人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("安全员")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("联系方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("风险成因")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("导致后果")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("管控措施")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("应急措施")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("警示图片")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-3 caption"},[_c('p',[_vm._v("二维码")])])
}]

export { render, staticRenderFns }