<template>
  <div class="from_outer">
    <div class="card">
      <div class="title">{{ info.department }} - {{ info.name }}(风险告知卡)</div>
      <div class="card-strip">
        <div class="card-3 caption">
          <p>检查频率</p>
        </div>
        <div class="card-4">
          <p class="p1">
            {{ info.check_times }}
          </p>
        </div>
        <div class="card-3 caption">
          <p>风险等级</p>
        </div>
        <div class="card-4" style="padding: 0">
          <div
            class="grade"
            :style="{
              color: item.active ? '#ffffff' : '',
              background: item.active ? item.color : '',
            }"
            v-for="(item, index) in color_list"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="card-strip">
        <div class="card-3 caption">
          <p>责任部门</p>
        </div>
        <div class="card-4">
          <p class="p1">
            {{ info.department }}
          </p>
        </div>
        <div class="card-3 caption">
          <p>责任人</p>
        </div>
        <div class="card-4" v-if="info.liableMan">
          <p class="p1" v-if="info.liableMan.length != 0">
            {{ info.liableMan[0].nickname }}
          </p>
        </div>
      </div>
      <div class="card-strip">
        <div class="card-3 caption">
          <p>安全员</p>
        </div>
        <div class="card-4">
          <p class="p1">
            {{ safety_person }}
          </p>
        </div>
        <div class="card-3 caption">
          <p>联系方式</p>
        </div>
        <div class="card-4">
          <div class="safety">
            <p v-for="(item, index) in safetyMan" :key="index">
              {{ item.nickname }} - {{ item.mobile }}
            </p>
          </div>
        </div>
      </div>

      <div class="card-strip">
        <div class="card-3 caption">
          <p>风险成因</p>
        </div>
        <div class="card-2">
          <p class="p1">
            {{ info.ext_causes }}
          </p>
        </div>
      </div>

      <div class="card-strip">
        <div class="card-3 caption">
          <p>导致后果</p>
        </div>
        <div class="card-2">
          <p class="p1">
            {{ info.ext_danger }}
          </p>
        </div>
      </div>

      <!-- <div class="card-strip">
        <div class="card-3 caption">
          <p>危险因素</p>
        </div>
        <div class="card-2">
          <p class="p1">{{ info.ext_tags }}</p>
        </div>
      </div> -->

      <div class="card-strip">
        <div class="card-3 caption">
          <p>管控措施</p>
        </div>
        <div class="card-2">
          <p class="p1">{{ info.ext_controll }}</p>
        </div>
      </div>

      <div class="card-strip">
        <div class="card-3 caption">
          <p>应急措施</p>
        </div>
        <div class="card-2">
          <!-- <p class="p1">
            及时上报部门负责人，及时报告校园110指挥中心，第一时间组织救援。遇重大突发情况及时拨打110/120/119
          </p> -->
          <p class="p1">{{ info.ext_plans }}</p>
        </div>
      </div>

      <div class="card-strip">
        <div class="card-3 caption">
          <p>警示图片</p>
        </div>
        <div class="card-2" style="justify-content: center">
          <el-image
            v-for="(item, index) in img_List"
            :key="index"
            class="img-sty"
            :src="item"
          >
          </el-image>
        </div>
      </div>
      <div class="card-strip">
        <div class="card-3 caption">
          <p>二维码</p>
        </div>
        <div class="card-2" style="justify-content: center">
          <el-image class="img-ewm" :src="info.qr_code"> </el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dangerousdetail } from "../../assets/request/api";
export default {
  name: "risk_particulars",
  data() {
    return {
      info: {},
      img_List: [],
      //安全员
      safety_person: "",
      //安全员加手机号
      safetyMan: "",
      //等级
      color_list: [],
    };
  },

  created() {
    let { id } = this.$route.query;
    console.log(id);
    dangerousdetail({ id }).then((res) => {
      this.info = res.data.data;
      console.log(this.info);
      let { icons, ext_icons, safetyMan } = res.data.data;
      let icons_List = [];
      if (icons.length != 0) {
        icons_List = Object.values(icons);
      }
      console.log(icons);
      console.log(icons_List);
      console.log(ext_icons);
      let ext_iconsList = ext_icons ? ext_icons.split(",") : [];
      this.img_List = icons_List.concat(ext_iconsList);
      console.log(this.img_List);
      let safety_person = safetyMan.map((item) => item.nickname).join(",");
      this.safety_person = safety_person;
      this.safetyMan = safetyMan;

      this.color_list = res.data.color_list;
    });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
@border:1px solid #333333;
.card {
  margin: 30px;
  border: 3px solid #333333;
}

.title {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  font-size: 36px;
  font-weight: bold;
  border-bottom: @border;
}
.card-strip {
  border-bottom: @border;
  display: flex;
  & > div {
    font-size: 28px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-right: @border;
    padding: 20px 0;
  }
  p {
    padding-left: 20px;
  }
  .p1 {
    font-weight: 500;
    font-size: 23px;
  }
}

.card-3 {
  width: 20%;
}

.card-4 {
  width: 30%;
}
.card-2 {
  width: 80%;
}
.img-sty {
  margin-left: 20px;
  width: 60px;
  height: 60px;
}
.img-ewm {
  width: 150px;
  height: 150px;
}

.card-4:last-child {
  border-right: none;
}
.card :last-child {
  border-bottom: none;
}
.caption {
  background: #f2f2f2;
}
.safety {
  p {
    font-size: 15px;
  }
}
.grade {
  display: inline-block;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
</style>